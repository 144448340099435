import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import About3 from '../../components/about3/about3';
import Navbar from '../../components/Navbar/Navbar'
import Scrollbar from '../../components/scrollbar/scrollbar';
import MisssionSection from '../../components/MisssionSection/MisssionSection';
import AwardSection from '../../components/AwardSection/AwardSection';
import Pricing from '../../components/Pricing/Pricing';
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo.png'

const AboutPage = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            <About3/>
            <MisssionSection/>
            <Pricing/>
            <AwardSection/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
