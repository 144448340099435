import React from 'react'
import { Link } from 'react-router-dom'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const CtaSection2 = (props) => {
    return (


        <section className="cta-s2-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-md-8 col-12">
                        <div className="text">
                            <h3>We try to Help our Every Single Client.</h3>
                            <p>Who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window</p>
                            <Link onClick={ClickHandler} to="/contact" className="theme-btn-s2">Contact us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaSection2;