import cimg1 from '../images/case-studies/img-1.jpg'
import cimg2 from '../images/case-studies/img-2.jpg'
import cimg3 from '../images/case-studies/img-3.jpg'
import cimg4 from '../images/case-studies/img-4.jpg'
import cimg5 from '../images/case-studies/img-5.jpg'
import cimg6 from '../images/case-studies/img-6.jpg'



const Cases = [
    {
        Id: '1',
        cTitle: 'Criminal Law', 
        slug: 'Criminal-Law',
        cImg:cimg1,
        filterName: 'all civil flaw',
        subTitle:'Law, Civil',
    },
    {
        Id: '2',
        cTitle: 'Family Law', 
        slug: 'Family-Law',
        cImg:cimg2,
        filterName: 'all flaw',
        subTitle:'Law, Family',
    },
    {
        Id: '3',
        cTitle: 'Real Estate', 
        slug: 'Real-Estate',
        filterName: 'all blaw',
        cImg:cimg3,
        subTitle:'Law, Real Estate',
    },
    {
        Id: '4',
        cTitle: 'Business Law', 
        slug: 'Business-Law',
        filterName: 'all civil',
        cImg:cimg4,
        subTitle:'Law, Business',
    },
    {
        Id: '5',
        cTitle: 'Devorce Law', 
        slug: 'devorce-Laws',
        filterName: 'all blaw civil',
        cImg:cimg5,
        subTitle:'Law, Devorce',
    },
    {
        Id: '6',
        cTitle: 'Real Estate', 
        slug: 'Real-Estates',
        filterName: 'all flaw',
        cImg:cimg6,
        subTitle:'Law, Estate',
    }
]

export default Cases;