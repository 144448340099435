import React from 'react';
import { Link } from 'react-router-dom';
import Practices from '../../api/Practices';


const Practice = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className={`section-padding ${props.sClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className={`section-title ${props.tClass}`}>
                            <span>Law Services</span>
                            <h2>Practice Areas</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="service-grids clearfix">
                            {Practices.map((practice, Pitem) => (
                                <div className="grid" key={Pitem}>
                                    <div className="icon">
                                        <i className={`fi ${practice.sIcon}`}></i>
                                    </div>
                                    <h3><Link onClick={ClickHandler} to={`/practice-single/${practice.slug}`}>{practice.sTitle}</Link></h3>
                                    <p>{practice.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Practice;