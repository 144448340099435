import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cases from '../../api/case';
import Isotope from 'isotope-layout';


const CaseStudies = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isotope, setIsotope] = useState(null);
    const [filterKey, setFilterKey] = useState('all');

    useEffect(() => {
        setIsotope(
            new Isotope('.filter-container', {
                itemSelector: '.filter-item',
                layoutMode: 'fitRows',
            })
        );
    }, []);


    useEffect(() => {
        if (isotope) {
            // sanity check
            filterKey === '*'
                ? isotope.arrange({ filter: `*` })
                : isotope.arrange({ filter: `.${filterKey}` });
        }
    }, [isotope, filterKey]);


    setTimeout(function () {
        if (isotope) {
            // sanity check
            filterKey === '*'
                ? isotope.arrange({ filter: `*` })
                : isotope.arrange({ filter: `.${filterKey}` });
        };
    }, 500);




    return (
        <section className={`case-studies-section section-padding ${props.cSClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="section-title-s2">
                            <span>Case solved</span>
                            <h2>Case studies</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12 sortable-grids">
                        <div className="grid-filters">
                            <ul>
                                <li>
                                    <button onClick={() => setFilterKey('all')} className={`${filterKey === 'all' ? 'current' : ''}`}>All Case</button>
                                </li>
                                <li>
                                    <button onClick={() => setFilterKey('civil')} className={`${filterKey === 'civil' ? 'current' : ''}`}>Civil Litigation</button>
                                </li>
                                <li>
                                    <button onClick={() => setFilterKey('flaw')} className={`${filterKey === 'flaw' ? 'current' : ''}`}>Family Law</button>
                                </li>
                                <li>
                                    <button onClick={() => setFilterKey('blaw')} className={`${filterKey === 'blaw' ? 'current' : ''}`}>Business Law</button>
                                </li>
                            </ul>
                        </div>
                        <div className="grid-container filter-container">
                            {Cases.slice(0, 6).map((cases, cs) => (
                                <div className={`filter-item grid grid-item  ${cases.filterName}`} key={cs}>
                                    <img src={cases.cImg} alt="" />
                                    <div className="hover-content">
                                        <div className="text">
                                            <h4><Link onClick={ClickHandler} to={`/case-single/${cases.slug}`}>{cases.cTitle}</Link></h4>
                                            <span>{cases.subTitle}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CaseStudies;