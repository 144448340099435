import React from 'react'
import { Link } from 'react-router-dom'
import Attorneys from '../../api/attorneys'


const Attorney = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="team-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="section-title-s2">
                            <span>Experience team</span>
                            <h2>Team members</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="team-grids">
                            {Attorneys.slice(0,3).map((attorney, aitem) => (
                                <div className="grid" key={aitem}>
                                    <div className="member-pic">
                                        <img src={attorney.AtImg} alt="" />
                                    </div>
                                    <div className="info">
                                        <h3><Link onClick={ClickHandler} to={`/team-single/${attorney.slug}`}>{attorney.name}</Link></h3>
                                        <span>{attorney.title}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Attorney;
