import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage/HomePage'
import HomePage2 from '../HomePage2/HomePage2';
import HomePage3 from '../HomePage3/HomePage3';
import AboutPage from '../AboutPage/AboutPage';
import AboutPageS2 from '../AboutPageS2/AboutPageS2';
import AttorneysPage from '../AttorneysPage/AttorneysPage';
import AttorneySinglePage from '../AttorneySinglePage/AttorneySinglePage';
import FaqPage from '../FaqPage';
import TestimonialPage from '../TestimonialPage/TestimonialPage';
import PracticePage from '../PracticePage/PracticePage';
import PracticePageS2 from '../PracticePageS2/PracticePageS2';
import PracticeSinglePage from '../PracticeSinglePage/PracticeSinglePage';
import CasePage from '../CasePage/CasePage';
import CasePageS2 from '../CasePageS2/CasePageS2';
import CaseSinglePage from '../CaseSinglePage/CaseSinglePage';
import BlogPage from '../BlogPage/BlogPage'
import BlogPageLeft from '../BlogPageLeft/BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth/BlogPageFullwidth'
import BlogDetails from '../BlogDetails/BlogDetails'
import BlogDetailsFull from '../BlogDetailsFull/BlogDetailsFull'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide/BlogDetailsLeftSiide'
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="home-2" element={<HomePage2 />} />
          <Route path="home-3" element={<HomePage3 />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="about-s2" element={<AboutPageS2 />} />
          <Route path="team" element={<AttorneysPage />} />
          <Route path="team-single/:slug" element={<AttorneySinglePage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="testimonials" element={<TestimonialPage />} />
          <Route path="practice-area" element={<PracticePage />} />
          <Route path="practice-area-s2" element={<PracticePageS2 />} />
          <Route path="practice-single/:slug" element={<PracticeSinglePage />} />
          <Route path="case-studies" element={<CasePage />} />
          <Route path="case-studies-s2" element={<CasePageS2 />} />
          <Route path="case-single/:slug" element={<CaseSinglePage />} />
          <Route path='blog' element={<BlogPage />} />
          <Route path='blog-left-sidebar' element={<BlogPageLeft />} />
          <Route path='blog-fullwidth' element={<BlogPageFullwidth />} />
          <Route path='blog-single/:slug' element={<BlogDetails />} />
          <Route path='blog-single-left-sidebar/:slug' element={<BlogDetailsLeftSiide />} />
          <Route path='blog-single-fullwidth/:slug' element={<BlogDetailsFull />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='404' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
