import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Navbar from '../../components/Navbar/Navbar'
import Scrollbar from '../../components/scrollbar/scrollbar';
import Attorney2 from '../../components/attorneys2/Attorney2';
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo.png'

const AttorneysPage = () => {
    return (
        <Fragment>
            <Navbar  Logo={Logo}/>
            <PageTitle pageTitle={'Our Attorneys'} pagesub={'Attorneys'} />
            <Attorney2/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AttorneysPage;
