import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/slide-1.jpg'
import hero2 from '../../images/slider/slide-2.jpg'
import hero3 from '../../images/slider/slide-3.jpg'



const Hero = (props) => {

    var settings = {
        dots: false,
        arrows: true,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true
    };

    return (

        <div className={`hero hero-slider-wrapper ${props.heroCLass}`}>
            <div className="hero-container">
                <div className="hero-wrapper">
                    <Slider {...settings}>
                        <div className="slide">
                            <div className="slide-inner" style={{ backgroundImage: `url(${hero1})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col col-xl-6 col-lg-6 col-md-8 slide-caption">
                                            <div className="slide-subtitle">
                                                <p>Welcome to us...</p>
                                            </div>
                                            <div className="slide-title">
                                                <h2>Deep Understanding, Best Solutions</h2>
                                            </div>
                                            <div className="btns">
                                                <Link to="/about" className="theme-btn-s2">Explore More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide">
                            <div className="slide-inner" style={{ backgroundImage: `url(${hero2})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col col-xl-6 col-lg-6 col-md-8 slide-caption">
                                            <div className="slide-subtitle">
                                                <p>Welcome to us...</p>
                                            </div>
                                            <div className="slide-title">
                                                <h2>Committed to Helping Our Clients Succeed</h2>
                                            </div>
                                            <div className="btns">
                                                <Link to="/about" className="theme-btn-s2">Explore More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide">
                            <div className="slide-inner" style={{ backgroundImage: `url(${hero3})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col col-xl-6 col-lg-6 col-md-8 slide-caption">
                                            <div className="slide-subtitle">
                                                <p>Welcome to us...</p>
                                            </div>
                                            <div className="slide-title">
                                                <h2>Great Lawyers Great Law Firm.</h2>
                                            </div>
                                            <div className="btns">
                                                <Link to="/about" className="theme-btn-s2">Explore More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Hero;