import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Practice from '../../components/Practice';
import Logo from '../../images/logo.png'

const PracticePageS2 =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'Practice'} pagesub={'Practice'}/> 
            <Practice sClass={'service-section'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PracticePageS2;

