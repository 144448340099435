import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/hero';
import CtaSection from '../../components/CtaSection/CtaSection';
import About3 from '../../components/about3/about3';
import Practice from '../../components/Practice';
import TestimonialsContact from '../../components/TestimonialsContact/TestimonialsContact';
import CaseStudies from '../../components/CaseStudies/CaseStudies';
import CtaSection2 from '../../components/CtaSection2/CtaSection2';
import Attorney from '../../components/attorneys';
import PartnerSection from '../../components/PartnerSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/footer-logo.png'

const HomePage3 = () => {
    return (
        <Fragment>
            <Navbar hclass={'header-style-3'} Logo={Logo} />
            <Hero heroCLass={'hero-style-3'}/>
            <CtaSection />
            <About3 />
            <Practice sClass={'service-section'}/>
            <TestimonialsContact />
            <CaseStudies />
            <CtaSection2 />
            <Attorney />
            <PartnerSection />
            <BlogSection vClass={'d-none'} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage3;