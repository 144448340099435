import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Attorneys from '../../api/attorneys'
import Logo from '../../images/logo.png'

const AttorneySinglePage = (props) => {
    const { slug } = useParams()

    const AttorneyDetails = Attorneys.find(item => item.slug === slug)

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={AttorneyDetails.name} pagesub='Attorney Single' />
            <section className="attorny-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-9 col-12 order-lg-2 order-1">
                            <div className="attorny-single-content">
                                <div className="img-holder">
                                    <img src={AttorneyDetails.AtImg} alt="" />
                                </div>
                                <div className="attorney-title">
                                    <h2>{AttorneyDetails.name}</h2>
                                    <span>{AttorneyDetails.title}</span>
                                </div>

                                <div className="table-responsive info-table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Positon</td>
                                                <td>Seniour Attorny</td>
                                            </tr>
                                            <tr>
                                                <td>Practice Areas</td>
                                                <td>He is a expert lawyer and he run all over the area</td>
                                            </tr>
                                            <tr>
                                                <td>Location</td>
                                                <td>54 no street, melbord australia</td>
                                            </tr>
                                            <tr>
                                                <td>Phone</td>
                                                <td>6546746514654</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>email@email.com</td>
                                            </tr>
                                            <tr>
                                                <td>Social</td>
                                                <td>
                                                    <ul>
                                                        <li><Link onClick={ClickHandler} to="/team-single/Michel-Troat"><i className="ti-facebook"></i></Link></li>
                                                        <li><Link onClick={ClickHandler} to="/team-single/Michel-Troat"><i className="ti-twitter-alt"></i></Link></li>
                                                        <li><Link onClick={ClickHandler} to="/team-single/Michel-Troat"><i className="ti-linkedin"></i></Link></li>
                                                        <li><Link onClick={ClickHandler} to="/team-single/Michel-Troat"><i className="ti-pinterest"></i></Link></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Languages</td>
                                                <td>English(fluent), Greek , chinese.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h3>Overview</h3>
                                <p>His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm toward</p>
                                <h3>Education</h3>
                                <ol>
                                    <li>Travelling salesman - and above it there hung a picture that he had.</li>
                                    <li>Recently cut out of an illustrated magazine and housed in a nice</li>
                                    <li>It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy</li>
                                </ol>
                                <h3>Certificate</h3>
                                <ol>
                                    <li>Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine</li>
                                    <li>Travelling salesman - and above it there hung a picture that he had.</li>
                                    <li>Recently cut out of an illustrated magazine and housed in a nice</li>
                                    <li>It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy</li>
                                </ol>
                                <h3>Related cases</h3>
                                <ol>
                                    <li>Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine</li>
                                    <li>Travelling salesman - and above it there hung a picture that he had.</li>
                                    <li>Recently cut out of an illustrated magazine and housed in a nice</li>
                                    <li>It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy</li>
                                </ol>
                                <div className="contact">
                                    <h3>Contact with me</h3>
                                    <div className="contact-form">
                                        <form onSubmit={SubmitHandler}>
                                            <div className="half-col">
                                                <input type="text" className="form-control" placeholder="Your Name" />
                                            </div>
                                            <div className="half-col">
                                                <input type="email" className="form-control" placeholder="Your Email" />
                                            </div>
                                            <div>
                                                <textarea className="form-control" placeholder="Case Description..."></textarea>
                                            </div>
                                            <div className="submit-btn-wrapper">
                                                <button type="submit" className="theme-btn-s2">Appointment</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-12  order-lg-1 order-2">
                            <div className="service-single-sidebar">
                                <div className="widget attorney-list-widget">
                                    <h3>Our Attroneys</h3>
                                    <ul>
                                        {Attorneys.map((attorney, aitem) => (
                                            <li key={aitem}><Link onClick={ClickHandler} to={`/team-single/${attorney.slug}`}>{attorney.name}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="widget contact-widget">
                                    <h3>Contact info</h3>
                                    <ul>
                                        <li><span>Phone: </span>64646+416(85)</li>
                                        <li><span>Email: </span>demo@doc.com, example@doc.com</li>
                                        <li><span>Address: </span>45 no streed melborn, Astralia</li>
                                        <li><span>Office hour: </span>10am - 5pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AttorneySinglePage;
