import React from 'react'

const CtaSection = (props) => {
    return (
        <section className="cta-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix">
                            <div className="intro-text">
                                <span>We make Your Case Easy</span>
                                <h3>We are a International Law Group, Provide Quality Service for Customer</h3>
                            </div>
                            <div className="details">
                                <p>He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaSection;