import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/hero';
import CtaSection from '../../components/CtaSection/CtaSection';
import About from '../../components/about/about';
import Practice from '../../components/Practice';
import TestimonialsContact from '../../components/TestimonialsContact/TestimonialsContact';
import CaseStudies from '../../components/CaseStudies/CaseStudies';
import CtaSection2 from '../../components/CtaSection2/CtaSection2';
import Attorney from '../../components/attorneys';
import PartnerSection from '../../components/PartnerSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.png'

const HomePage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'} Logo={Logo} />
            <Hero heroCLass={'hero-style-1'}/>
            <CtaSection />
            <About />
            <Practice sClass={'service-section-s2'} tClass={'section-title-s2'}/>
            <TestimonialsContact />
            <CaseStudies />
            <CtaSection2 />
            <Attorney />
            <PartnerSection />
            <BlogSection vClass={'d-none'} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage;