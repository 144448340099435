import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Link } from 'react-router-dom'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Cases from '../../api/case';
import FaqSection from './FaqSection';
import Logo from '../../images/logo.png'


const CaseSinglePage = (props) => {
    const { slug } = useParams()

    const caseDetails = Cases.find(item => item.slug === slug)

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={caseDetails.cTitle} pagesub={'Project'} />
            <section className="case-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-9 col-12 order-lg-2 order-1">
                            <div className="service-single-content">
                                <div className="img-holder">
                                    <img src={caseDetails.cImg} alt="" />
                                </div>
                                <h2>Public interest and pro bono projects</h2>
                                <p>He found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he.</p>
                                <p>His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm toward</p>
                                <div className="challenge-solution-section theme-accordion-s1">
                                    <FaqSection />
                                </div>

                            </div>
                        </div>
                        <div className="col col-lg-3 col-12 order-lg-1 order-2">
                            <div className="service-single-sidebar">
                                <div className="widget service-list-widget">
                                    <h3>Recent cases</h3>
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/case-single/Criminal-Law">Public Interest and Pro Bono Projects</Link></li>
                                        <li><Link onClick={ClickHandler} to="/case-single/Criminal-Law">Stanford Copyright and Fair Use Center </Link></li>
                                        <li><Link onClick={ClickHandler} to="/case-single/Criminal-Law">Legal Resource Center provides up-to-date information</Link></li>
                                        <li><Link onClick={ClickHandler} to="/case-single/Criminal-Law">When a person is injured, tort law will govern whether that </Link></li>
                                    </ul>
                                </div>
                                <div className="widget contact-widget">
                                    <h3>Contact info</h3>
                                    <ul>
                                        <li><span>Phone: </span>64646+416(85)</li>
                                        <li><span>Email: </span>demo@doc.com, example@doc.com</li>
                                        <li><span>Address: </span>45 no streed melborn, Astralia</li>
                                        <li><span>Office hour: </span>10am - 5pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default CaseSinglePage;
