import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2'
import Hero from '../../components/hero/hero';
import CtaSection from '../../components/CtaSection/CtaSection';
import About2 from '../../components/about2/about2';
import Practice from '../../components/Practice';
import TestimonialsContactS2 from '../../components/TestimonialsContactS2/TestimonialsContactS2';
import CaseStudies from '../../components/CaseStudies/CaseStudies';
import CtaSection2 from '../../components/CtaSection2/CtaSection2';
import Attorney from '../../components/attorneys';
import PartnerSection from '../../components/PartnerSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage2 = () => {
    return (
        <Fragment>
            <Navbar2 hclass={'header-style-2'}/>
            <Hero heroCLass={'hero-style-2'}/>
            <CtaSection />
            <About2 />
            <Practice sClass={'service-section'}/>
            <TestimonialsContactS2 />
            <CaseStudies cSClass={'case-studies-section-s2'}/>
            <CtaSection2 />
            <Attorney />
            <PartnerSection />
            <BlogSection vClass={'d-none'} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage2;