import React from "react";
import aImg from '../../images/awards/img-1.jpg'
import aImg2 from '../../images/awards/img-2.jpg'
import aImg3 from '../../images/awards/img-3.jpg'


const AwardSection = (props) => {


    return (
        <section className="award-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="section-title-s2">
                            <span>Awards</span>
                            <h2>Awards we won</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="award-grids">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={aImg} alt="" />
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={aImg2} alt="" />
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={aImg3} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AwardSection;