import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import HeaderTopbar from '../HeaderTopbar';


const Header = (props) => {
    const [menuActive, setMenuState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <header id="header" className={`site-header header-style-1 ${props.hclass}`}>
            <HeaderTopbar containerClass={'container'}/>
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <div className="navbar-header">
                        <MobileMenu />
                        <Link onClick={ClickHandler} className="navbar-brand" to="/home"><img src={props.Logo} alt="" /></Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                        <ul className="nav navbar-nav">
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Home</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/home">Home</Link></li>
                                    <li><Link onClick={ClickHandler} to="/home-2">Home style 2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/home-3">Home style 3</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Pages</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about-s2">About style 2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team">Attorneys</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team-single/Jonathon-Danial">Attorney single</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                    <li><Link onClick={ClickHandler} to="/404">404</Link></li>
                                    <li className="menu-item-has-children">
                                        <Link onClick={ClickHandler} to="/">Third level</Link>
                                        <ul className="sub-menu">
                                            <li><Link onClick={ClickHandler} to="/">Level 3</Link></li>
                                            <li><Link onClick={ClickHandler} to="/">Level 3</Link></li>
                                            <li><Link onClick={ClickHandler} to="/">Level 3</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Practice Areas</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/practice-area">Practice Areas</Link></li>
                                    <li><Link onClick={ClickHandler} to="/practice-area-s2">Practice Areas s2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/practice-single/Criminal-Law">Practice single</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Cases</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/case-studies">Cases</Link></li>
                                    <li><Link onClick={ClickHandler} to="/case-studies-s2">Cases s2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/case-single/Criminal-Law">Cases single</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Blog</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/blog">Blog Default</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-single/Amy-Meselson-Lawyer-who-fought-for-the-right-of-vulnerable.">Blog Details default</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-single-left-sidebar/Amy-Meselson-Lawyer-who-fought-for-the-right-of-vulnerable.">Blog Details left sidebar</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-single-fullwidth/Amy-Meselson-Lawyer-who-fought-for-the-right-of-vulnerable.">Blog Details fullwidth</Link></li>
                                </ul>
                            </li>
                            <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                        </ul>
                    </div>

                    <div className="search-quote">
                        <div className="header-search-area">
                            <div>
                                <button className="btn open-btn" onClick={() => setMenuState(!menuActive)}><i className="fi flaticon-magnifying-glass-browser"></i></button>
                            </div>
                        </div>
                        <div className="quote">
                            <Link onClick={ClickHandler} to="/contact" className="theme-btn">Free consultation</Link>
                        </div>
                    </div>
                </div>
            </nav>
            <div className={`header-search-form ${menuActive ? "active" : ""}`}>
                <div className="close-btn" onClick={() => setMenuState(!menuActive)}><i className='ti-close'></i></div>
                <form className="form" onSubmit={SubmitHandler}>
                    <div>
                        <input type="text" className="form-control" placeholder="Search here" />
                    </div>
                    <button type="submit" className="btn"><i className="fi flaticon-magnifying-glass-browser"></i></button>
                </form>
            </div>
        </header>
    )
}

export default Header;