import simg from '../images/service-single/img-1.jpg'
import simg2 from '../images/service-single/img-2.jpg'
import simg3 from '../images/service-single/img-3.jpg'
import simg4 from '../images/service-single/img-4.jpg'
import simg5 from '../images/service-single/img-5.jpg'
import simg6 from '../images/service-single/img-6.jpg'

const Practices = [
    {
        Id: '1',
        sImg: simg,
        sIcon: 'flaticon-jail',
        sTitle: 'Criminal Law',
        slug: 'Criminal-Law',
        description: 'He lay on his armour-like back, and if he lifted his head a little could see his brown belly facets inhreit',
        des2: 'Samsa was a travelling salesman  and above it there',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '2',
        sImg: simg2,
        sIcon: 'flaticon-couple',
        sTitle: 'Family Law',
        slug: 'Family-Law',
        description: 'He lay on his armour-like back, and if he lifted his head a little could see his brown belly facets inhreit',
        des2: 'Samsa was a travelling salesman  and above it there',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-015-patient',
    },
    {
        Id: '3',
        sImg: simg3,
        sIcon: 'flaticon-wounded',
        sTitle: 'Travel Accident',
        slug: 'Travel-Accident',
        description: 'He lay on his armour-like back, and if he lifted his head a little could see his brown belly facets inhreit',
        des2: 'Samsa was a travelling salesman  and above it there',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '4',
        sImg: simg4,
        sIcon: 'flaticon-robber-1',
        sTitle: 'Identity Theft',
        slug: 'Identity-Theft',
        description: 'He lay on his armour-like back, and if he lifted his head a little could see his brown belly facets inhreit',
        des2: 'Samsa was a travelling salesman  and above it there',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '5',
        sImg: simg5,
        sIcon: 'flaticon-laptop',
        sTitle: 'Legal Advice',
        slug: 'Legal-Advice',
        description: 'He lay on his armour-like back, and if he lifted his head a little could see his brown belly facets inhreit',
        des2: 'Samsa was a travelling salesman  and above it there',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    },
    {
        Id: '6',
        sImg: simg6,
        sIcon: 'flaticon-balance',
        sTitle: 'Personal Injury',
        slug: 'Personal-Injury',
        description: 'He lay on his armour-like back, and if he lifted his head a little could see his brown belly facets inhreit',
        des2: 'Samsa was a travelling salesman  and above it there',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-017-agreement',
    },
]
export default Practices;