import React from 'react'
import MissionTab from './MissionTab';
import FaqSection from './FaqSection';

const MisssionSection = (props) => {
    return (
        <section className="mission-vision-faq section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6">
                        <div className="mission-vision">
                            <MissionTab/>
                        </div>
                    </div>
                    <div className="col col-lg-6">
                        <div className="faq-section">
                            <FaqSection/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MisssionSection;