import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import About3 from '../../components/about3/about3';
import Navbar from '../../components/Navbar/Navbar'
import Scrollbar from '../../components/scrollbar/scrollbar';
import MisssionSection from '../../components/MisssionSection/MisssionSection';
import TestimonialsContact from '../../components/TestimonialsContact/TestimonialsContact';
import Attorney from '../../components/attorneys';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo.png'

const AboutPageS2 = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} Logo={Logo}/>
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            <About3/>
            <MisssionSection/>
            <TestimonialsContact />
            <Attorney />
            <PartnerSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPageS2;
