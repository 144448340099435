import React from 'react'
import ts1 from '../../images/testimonials/img-1.jpg'
import ts2 from '../../images/testimonials/img-2.jpg'
import ContactFrom from '../ContactFrom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialsContactS2 = (props) => {


    var settings = {
        dots: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section className="testimonials-contact-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="testimonial-grids">
                            <Slider {...settings}>
                                <div className="grid">
                                    <div className="quote">
                                        <p>Travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady</p>
                                    </div>
                                    <div className="details">
                                        <div className="client-pic">
                                            <img src={ts1} alt="" />
                                        </div>
                                        <h4>Michel Jhon</h4>
                                        <span>CEO, of details</span>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="quote">
                                        <p>Travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady</p>
                                    </div>
                                    <div className="details">
                                        <div className="client-pic">
                                            <img src={ts2} alt="" />
                                        </div>
                                        <h4>Dean Jhon</h4>
                                        <span>CEO</span>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="quote">
                                        <p>Travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady</p>
                                    </div>
                                    <div className="details">
                                        <div className="client-pic">
                                            <img src={ts1} alt="" />
                                        </div>
                                        <h4>Michel Jhon</h4>
                                        <span>CEO, of details</span>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="row contact-row">
                    <div className="separator"></div>
                    <div className="col col-lg-6 col-12">
                        <div className="contact-area">
                            <h3>Are you interest to contact with us?</h3>
                            <p>Raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather. </p>
                            <ul>
                                <li>
                                    <div className="icon">
                                        <i className="ti-mobile"></i>
                                    </div>
                                    <span>Our Phone:</span> 54562364769744, 8562364769744
                                </li>
                                <li>
                                    <div className="icon">
                                        <i className="ti-email"></i>
                                    </div>
                                    <span>Email Address:</span> demo@example.com,  example@demo.com
                                </li>
                                <li>
                                    <div className="icon">
                                        <i className="ti-time"></i>
                                    </div>
                                    <span>Offuce hour:</span> 9AM - 5PM
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="contact-form">
                            <ContactFrom />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TestimonialsContactS2;