import React from 'react'
import { Link } from 'react-router-dom'



const Pricing = (props) => {

    return (
        <section className="pricing-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="section-title">
                            <span>Our pricing</span>
                            <h2>Pricing plan</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col col-lg-10">
                        <div className="pricing-grids clearfix">
                            <div className="grid">
                                <div className="pricing-header">
                                    <div className="icon">
                                        <i className="fi flaticon-royal-crown-outline-for-a-prince"></i>
                                    </div>
                                    <h3>Basic</h3>
                                    <p>Gregor then turned to look out the window the dull. </p>
                                    <div className="price">
                                        <p>$55.00 <span>/mo</span></p>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li><i className="ti-check-box"></i> Urned to look out the window</li>
                                        <li><i className="ti-check-box"></i> Raising a heavy fur muff that</li>
                                        <li><i className="ti-check-box"></i> Turned to look out the</li>
                                        <li><i className="ti-check-box"></i> Raising a heavy fur muff that</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <Link to="/pricing" className="theme-btn-s4">Get Now</Link>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="pricing-header">
                                    <div className="icon">
                                        <i className="fi flaticon-standard"></i>
                                    </div>
                                    <h3>Standard</h3>
                                    <p>Gregor then turned to look out the window the dull. </p>
                                    <div className="price">
                                        <p>$55.00 <span>/mo</span></p>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li><i className="ti-check-box"></i> Urned to look out the window</li>
                                        <li><i className="ti-check-box"></i> Raising a heavy fur muff that</li>
                                        <li><i className="ti-check-box"></i> Turned to look out the</li>
                                        <li><i className="ti-check-box"></i> Raising a heavy fur muff that</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <Link to="/pricing" className="theme-btn-s4">Get Now</Link>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="pricing-header">
                                    <div className="icon">
                                        <i className="fi flaticon-briefcase"></i>
                                    </div>
                                    <h3>Professional</h3>
                                    <p>Gregor then turned to look out the window the dull. </p>
                                    <div className="price">
                                        <p>$55.00 <span>/mo</span></p>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li><i className="ti-check-box"></i> Urned to look out the window</li>
                                        <li><i className="ti-check-box"></i> Raising a heavy fur muff that</li>
                                        <li><i className="ti-check-box"></i> Turned to look out the</li>
                                        <li><i className="ti-check-box"></i> Raising a heavy fur muff that</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <Link to="/pricing" className="theme-btn-s4">Get Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing;