import React from 'react'
import abimg from '../../images/about-pic-single.png'


const About2 = (props) => {

    return (
        <section className="about-section-s2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-7">
                        <div className="about-text">
                            <span>Welcome To Our Industry</span>
                            <h3>We Have 25 Years of Experience in this are, so you Can Espect From Us Better Services</h3>
                            <p>Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with</p>
                            <div className="info">
                                <h4>Big Boss</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                    </div>
                    <div className="about-pic">
                        <img src={abimg} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;