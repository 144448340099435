import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import TestimonialsContactS2 from '../../components/TestimonialsContactS2/TestimonialsContactS2';
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo.png'

const TestimonialPage2 =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'Testimonial'} pagesub={'Testimonial'}/> 
            <TestimonialsContactS2 />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TestimonialPage2;
