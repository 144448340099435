import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';


const MissionTab = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="tablinks clearfix">
            <ul className="nav">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Our Mission
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Our Vission
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >
                            Our Value
                        </NavLink>
                    </NavItem>
                </Nav>
            </ul>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <p>Salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather.  textile samples lay spread out on the table - Samsa was a travelling.</p>

                            <p>raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather.  textile samples lay spread out on the table - Samsa was a travelling.</p>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <p>Salesman - and above it there hung a picand housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather.  textile samples lay spread out on the table - Samsa was a travelling.</p>

                    <p>raising a heavy fur muff that covered the whower arm towards the viewer. Gregor then turned to look out the window at the dull weather.  textile samples lay spread out on the table - Samsa was a travelling.</p>
                </TabPane>
                <TabPane tabId="3">
                    <p>Salesman - and above it there hung a picture that he had cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather.  textile samples lay spread out on the table - Samsa was a travelling.</p>

                    <p>raising a heavy fur muff that covered the whole of her arm towards the viewer. Gregor then turned to look out the window at the dull weather.  textile samples lay spread out on the table - Samsa was a travelling.</p>
                </TabPane>
            </TabContent>
        </div>

    );
}

export default MissionTab;